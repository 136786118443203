import { useEffect, useRef } from 'react';
import { useAppDispatch, markCompleted } from 'app/store';
import { calculateAllTopicsProgress } from 'app/helpers';

import type { data } from 'app';
import type { ProfileData } from 'app/store/profileSlice';

export const useMarkTopicCompletedEffect = (topicKeys: data.TopicWithActivityKeys[], data: ProfileData) => {
  const dispatch = useAppDispatch();
  const alreadySent = useRef();
  useEffect(() => {
    // topic is already completed
    const { user, topics: userTopics } = data;
    if (user.topicCompletedAt) {
      return;
    }
    // all goals exist
    //  for (const topicKey in userTopics) {
    //   const topic = userTopics[topicKey];
    //   if (!topic.goals) {
    //     console.log("topic goals not exist")
    //     return; 
    //   }
    // }
    const { total, completed } = calculateAllTopicsProgress(topicKeys, userTopics);
    if (total > 0 && completed / total === 1) {
      console.log("called markcompleted")
      dispatch(markCompleted({ scope: 'topic' }));
    }
  }, [data]);
};
